import * as React from 'react'
import withHydrationOnDemand from 'react-hydration-on-demand'
import dynamic from 'next/dynamic'

import {
  useConfig,
  useEnterpriseContext,
  useHeaderHeight,
  useRequestConfig,
  useSiteConfig,
  useSiteDefinition,
} from '@thg-commerce/enterprise-core'
import { PageType } from '@thg-commerce/enterprise-core/src/App/types'
import { withESI } from '@thg-commerce/enterprise-esi'
import { isMobileDevice } from '@thg-commerce/gravity-system'
import { styled } from '@thg-commerce/gravity-theme'

import FooterESI from '../../Footer/Footer'
import { HeaderGroup } from '../../HeaderGroup'

const FooterGlobalAccredditationsIconsComponent = dynamic<{}>(
  () =>
    import('@thg-commerce/enterprise-widget-global-accreditations').then(
      (mod) => mod.FooterGlobalAccredditationsIcons,
    ),
  { ssr: true },
)

// const HeaderGroup = withESI(
//   HeaderGroupESI,
//   'header',
//   'enableLayoutESI',
//   'ENABLE_LAYOUT_ESI',
// )
const Footer = withESI(
  FooterESI,
  'footer',
  'enableLayoutESI',
  'ENABLE_LAYOUT_ESI',
)

const FooterGlobalAccredditationsIcons = withHydrationOnDemand({
  on: ['visible'],
})(FooterGlobalAccredditationsIconsComponent)

export type WindowWithOptanon = Window & {
  Optanon?: { ToggleInfoDisplay?: () => void }
}

export interface FullHeaderLayoutProps {
  features?: {
    stickyHeader: boolean
    showStripBanner: boolean
    wishlistNotification: boolean
  }
  beforeMainContent?: React.ReactNode
  children: React.ReactNode
  pageType?: PageType
  pageLayoutData?: any
  $window?: WindowWithOptanon
}

const LayoutWrapper = styled.div<{ headerHeight: number }>`
  > main {
    &:target {
      scroll-margin-top: ${(props) => props.headerHeight}px;
    }
  }
`

export const FullHeaderLayout = ({
  features = {
    stickyHeader: true,
    showStripBanner: true,
    wishlistNotification: true,
  },
  beforeMainContent,
  children,
  ...props
}: FullHeaderLayoutProps) => {
  const { domain } = useSiteDefinition()
  const { userAgent } = useRequestConfig()
  const { assetPrefix } = useConfig()
  const { countryDomainMap, translatedCountryDomain } = useSiteConfig()
  const EnterpriseCtx = useEnterpriseContext()
  const headerHeight = useHeaderHeight()
  const flagPath =
    countryDomainMap &&
    `${assetPrefix}/static/flags/1x1/${countryDomainMap[domain]}.svg`

  const isMobile = isMobileDevice(userAgent)

  // TODO REBUILD-7710: Remove duplicate props
  const navigationData = props.pageLayoutData || {}

  return (
    <LayoutWrapper headerHeight={headerHeight}>
      <HeaderGroup
        wishlistNotification={features.wishlistNotification}
        stickyHeader={features.stickyHeader}
        showStripBanner={features.showStripBanner}
        flagPath={flagPath}
        userAgent={userAgent}
        isMobile={isMobile}
        headerHeight={headerHeight}
        pageType={props.pageType}
        // TODO REBUILD-7710: Remove duplicate props
        header={navigationData.header?.header}
        brands={navigationData.header?.brands}
      />
      <div
        id="cookie-modal-container"
        data-testid="cookie"
        style={{
          display: 'flex',
          position: 'fixed',
          bottom: 0,
          padding: 0,
          margin: '0px',
          width: '100%',
          zIndex: 99999,
        }}
        ref={EnterpriseCtx.cookieModalContainerRef}
      />
      {beforeMainContent && beforeMainContent}
      <main id="main-content">{children}</main>
      <Footer
        userAgent={userAgent}
        flagPath={flagPath}
        cookieSettingsHandler={() => {
          props.$window?.Optanon?.ToggleInfoDisplay &&
            props.$window?.Optanon?.ToggleInfoDisplay()
        }}
        Accreditations={FooterGlobalAccredditationsIcons}
        // TODO REBUILD-7710: Remove duplicate props
        navigation={navigationData.footer?.navigation}
        socialAccounts={navigationData.footer?.socialAccounts}
        paymentProviders={navigationData.footer?.paymentProviders}
        countryDomainList={translatedCountryDomain}
      />
    </LayoutWrapper>
  )
}
