import * as React from 'react'
import loadable from '@loadable/component'

import { useTheme } from '@thg-commerce/gravity-elements/theme'
import { css, spacing, styled, Text } from '@thg-commerce/gravity-theme'

const ChevronLeft = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronLeft'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const StyledBanner = styled.div`
  padding: ${spacing(2)};
  background-color: ${(props) => props.theme.colors.palette.greys.white};
`

const navigationStyle = css`
  ${Text('small', 'default')}
  text-decoration: none;
  outline: 0px;
  display: flex;
  align-items: center;
  width: fit-content;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:focus-visible {
    outline: solid 2px ${(props) => props.theme.colors.palette.brand.base};
  }
`

const StyledLink = styled.a`
  ${navigationStyle}
`

const StyledButton = styled.button`
  ${navigationStyle}
`

const StyledSpan = styled.span`
  width: 24px;
  height: 26px;
`

export interface BackwardsNavigationProps {
  text: string
  url?: string
  onClick?: () => void
  className?: string
  LinkWrapper?: React.ComponentType<{
    href: string
    children: React.ReactElement
    [x: string]: any
  }>
  dataTestId?: string
}

export const BackwardsNavigation = (props: BackwardsNavigationProps) => {
  const { LinkWrapper } = props
  const theme = useTheme()

  return (
    <StyledBanner className={props.className} data-testid={props.dataTestId}>
      {LinkWrapper && props.url ? (
        <LinkWrapper href={props.url}>
          <StyledLink>
            <StyledSpan>
              {theme.elements.breadCrumbs.chevronIcon.left.svgPath ? (
                <SvgIcon
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox={theme.elements.breadCrumbs.chevronIcon.viewBox}
                  width={theme.elements.breadCrumbs.chevronIcon.width}
                  height={theme.elements.breadCrumbs.chevronIcon.height}
                >
                  <path
                    d={theme.elements.breadCrumbs.chevronIcon.left.svgPath}
                    fillRule="evenodd"
                  />
                </SvgIcon>
              ) : (
                <ChevronLeft />
              )}
            </StyledSpan>
            {props.text}
          </StyledLink>
        </LinkWrapper>
      ) : props.url ? (
        <StyledLink href={props.url}>
          {theme.elements.breadCrumbs.chevronIcon.left.svgPath ? (
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.elements.breadCrumbs.chevronIcon.viewBox}
              width={theme.elements.breadCrumbs.chevronIcon.width}
              height={theme.elements.breadCrumbs.chevronIcon.height}
            >
              <path
                d={theme.elements.breadCrumbs.chevronIcon.left.svgPath}
                fillRule="evenodd"
              />
            </SvgIcon>
          ) : (
            <ChevronLeft />
          )}
          {props.text}
        </StyledLink>
      ) : (
        <StyledButton
          data-testid="backwards-nav-button"
          onClick={props.onClick}
        >
          {theme.elements.breadCrumbs.chevronIcon.left.svgPath ? (
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.elements.breadCrumbs.chevronIcon.viewBox}
              width={theme.elements.breadCrumbs.chevronIcon.width}
              height={theme.elements.breadCrumbs.chevronIcon.height}
            >
              <path
                d={theme.elements.breadCrumbs.chevronIcon.left.svgPath}
                fillRule="evenodd"
              />
            </SvgIcon>
          ) : (
            <ChevronLeft />
          )}
          {props.text}
        </StyledButton>
      )}
    </StyledBanner>
  )
}
